.player {
  height: 300px;
  width: 300px;
}

@media (max-width: 570px) {
  .player {
    height: 250px;
    width: 250px;
  }
}

.content {
  position: relative;
  padding: 0px;
  margin-bottom: 3px;
  width: 100%;
  height: 120px;
}

@media (max-width: 570px) {
  .content {
    height: 100px;
  }
}

.content p {
  color: #fff;
  font-size: 6em;
  position: absolute;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

@media (max-width: 570px) {
  .content p {
    font-size: 5em;
  }
}

.content p:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #9c9cbb;
}

.content p:nth-child(2) {
  color: #9c9cbb;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

.drawBorder {
  box-shadow: inset 0 0 0 2px #58afd1;
  color: #58afd1;
  transition: color 0.25s 0.0833333333s;
  position: relative;
}
.drawBorder::before,
.drawBorder::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}
.drawBorder::before {
  border-bottom-width: 4px;
  border-left-width: 4px;
}
.drawBorder::after {
  border-top-width: 4px;
  border-right-width: 4px;
}
.drawBorder:hover {
  color: #c94c53;
}
.drawBorder:hover::before,
.drawBorder:hover::after {
  border-color: #c94c53;
  transition: border-color 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}
.drawBorder:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}
.drawBorder:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.btn {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1rem "Roboto Slab", sans-serif;
  padding: 0.5em 0.5em;
  letter-spacing: 0.05rem;
  text-align: center;
  margin-top: 5px;
}
.btn:focus {
  outline: 2px dotted #55d7dc;
}
