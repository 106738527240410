.strong {
  font-weight: 600;
}
.projectcard {
  flex: 1;
  max-width: 430px;
  min-width: 380px;
  display: grid;
  grid-template-columns: 1.2fr 1fr min-content;
  grid-auto-rows: min-content;
  background: #fdfdfd;
  margin: 0.5rem;
  line-height: 1.5;
  position: relative;
  /* box-shadow: 0 4px 0 -2px #ddd, 1px 1px 0 0 #f7f7f7; */
  color: black;
  font-family: "Roboto Mono", monospace;
}

@media (max-width: 570px) {
  .projectcard {
    min-width: 310px;
    max-width: 310px;
  }
}
.projectcard:before,
.projectcard:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 35px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  /* box-shadow: 0 35px 20px #777; */
  transform: rotate(-4deg);
}
.projectcard::after {
  transform: rotate(4deg);
  right: 10px;
  left: auto;
}
.projectcard > * {
  padding: 0.6rem 1.2rem;
}

.name {
  grid-column: 1 / -1;
  grid-row: 2;
  padding-bottom: 1.2rem;
  font-size: 2rem;
  line-height: 1;
  font-weight: 500;
}

@media (max-width: 570px) {
  .name {
    font-size: 1.7rem;
  }
}
.info,
.location,
.type,
.pattern {
  grid-column: 1 / span 2;
}
.date {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  padding: 1.2rem 0;
}
.duration {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  padding: 0.2rem 0;
}
.date,
.duration,
.footnote,
.site {
  font-size: 85%;
}

.site {
  grid-column: 1 / span 2;
}
.date {
  grid-row: 3 / span 4;
  grid-column: 3 / 4;
}
.footnote {
  grid-column: 1 / 2;
}
.duration {
  grid-row: 7 / span 1;
  grid-column: 3 / 4;
  min-height: 10rem;
}

.sidetitle {
  display: inline-block;
  margin-bottom: 0.75rem;
  border-bottom: 0.5px dashed;
  padding: 0 0.8rem 0.75rem;
}
.name,
.info,
.location,
.duration {
  border-bottom: 0.5px dashed;
}
.info,
.location,
.type,
.footnote {
  border-right: 0.5px dashed;
}

.info {
  height: 68.2px;
}

@media (max-width: 570px) {
  .info {
    font-size: 14px;
  }
}
.checkbox {
  display: inline-block;
  pointer-events: none;
}
.checkbox:nth-child(1) {
  margin-right: 30px;
}

@media (max-width: 570px) {
  .checkbox:nth-child(1) {
    margin-right: 0px;
  }
  .checkbox {
    font-size: 12px;
  }
  .location {
    font-size: 12px;
  }
}
.input {
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input:checked + label:after {
  content: "";
  position: absolute;
  width: 22px;
  height: 5px;
  background: black;
  left: 3px;
  transform: rotate(-45deg);
  top: 10px;
  border-radius: 5px;
}
.checkbox label {
  padding: 5px 20px 5px 30px;
  position: relative;
}
.checkbox label:before {
  content: "";
  position: absolute;
  border: 1px dashed;
  width: 18px;
  height: 18px;
  left: 0;
}
.strip {
  grid-column: 1 / 3;
  grid-row: 1;
}

.one {
  grid-row: 1;
  grid-column: 3/4;
  height: 2.5rem;
}
.pattern {
  background-size: 150%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-size 1s ease-in;
  grid-row: 6 / span 2;
  min-height: 16.5rem;
}

@media (max-width: 570px) {
  .pattern {
    background-size: 210%;
  }
}
.pattern:hover {
  background-size: 100%;
}

/* buttons */

.emblaprojects__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #636b6b;
  padding: 0;
}

@media (max-width: 768px) {
  .emblaprojects__button {
    width: 20px;
    height: 20px;
  }
}

.emblaprojects__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.emblaprojects__button__svg {
  width: 100%;
  height: 100%;
}

.emblaprojects__buttonprev {
  left: 27px;
}

.emblaprojects__buttonnext {
  right: 27px;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: #efefef;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  content: "";
}

.embla__dot.isselected:after {
  background-color: #1bcacd;
  opacity: 1;
}
