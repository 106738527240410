@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Color Theme Swatches in Hex */
.IPREDE-2-1-hex {
  color: #ed9ad1;
}
.IPREDE-2-2-hex {
  color: #b6e3db;
}
.IPREDE-2-3-hex {
  color: #ffba69;
}
.IPREDE-2-4-hex {
  color: #ffdd7b;
}
.IPREDE-2-5-hex {
  color: #c94c53;
}

/* Color Theme Swatches in RGBA */
.IPREDE-2-1-rgba {
  color: rgba(237, 154, 209, 1);
}
.IPREDE-2-2-rgba {
  color: rgba(182, 227, 219, 1);
}
.IPREDE-2-3-rgba {
  color: rgba(255, 186, 105, 1);
}
.IPREDE-2-4-rgba {
  color: rgba(255, 221, 123, 1);
}
.IPREDE-2-5-rgba {
  color: rgba(201, 76, 83, 1);
}

/* Color Theme Swatches in HSLA */
.IPREDE-2-1-hsla {
  color: hsla(320, 69, 76, 1);
}
.IPREDE-2-2-hsla {
  color: hsla(169, 44, 80, 1);
}
.IPREDE-2-3-hsla {
  color: hsla(32, 100, 70, 1);
}
.IPREDE-2-4-hsla {
  color: hsla(44, 100, 74, 1);
}
.IPREDE-2-5-hsla {
  color: hsla(356, 53, 54, 1);
}

@font-face {
  font-family: "Charlevoix";
  src: url("./fonts/PPCharlevoix-Bold.otf");
}

@font-face {
  font-family: "Mori";
  src: url("./fonts/PPMori-Regular.otf");
}

@font-face {
  font-family: "Writer";
  src: url("./fonts/PPWriter-Thin.otf");
}
