@import url(https://fonts.googleapis.com/css?family=Fjalla+One);

.footer {
  position: relative;
  width: 100%;
  background: #cacaca;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 200px;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("https://i.imgur.com/TRVswNU.png");
  background-size: 1000px 100px;
}
.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animatewave 4s linear infinite;
}
.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animatewave_02 4s linear infinite;
}
.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animatewave 3s linear infinite;
}
.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animatewave_02 3s linear infinite;
}
@keyframes animatewave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}
@keyframes animatewave_02 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}

.text {
  color: #fff;
  text-transform: uppercase;
  font-size: 42px;
  margin: 0;
  line-height: 47px;
  letter-spacing: 2px;
}

.title {
  display: block;
  float: left;
  text-shadow: #533d4a 1px 1px, #533d4a 2px 2px, #533d4a 3px 3px,
    #533d4a 4px 4px;
  min-width: 10px;
  min-height: 10px;
  position: relative;
  color: #c94c53;
  font-family: "Fjalla One", sans-serif;
}

.footerElementName {
  color: var(--chakra-colors-gray-600);
  font: bold 18px/1.4 "Open Sans", arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin-left: 1rem;
  position: relative;
}

@media (max-width: 570px) {
  .footerElementName {
    font: bold 14px/1.4 "Open Sans", arial, sans-serif;
  }
}

.footerElementName:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--chakra-colors-gray-600);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.footerElementName:hover:after {
  width: 100%;
  left: 0;
}
