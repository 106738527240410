.span {
  font-family: "Dancing Script", cursive;
  font-size: 40px;
}

.title {
  font-family: "Nunito", sans-serif;
}

.goUp {
  animation: 2s anim-goUp ease-out;
}
@keyframes anim-goUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
