.emblatech {
  position: relative;
  background-color: transparent;
  padding: 20px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.emblatech__viewport {
  overflow: hidden;
  width: 100%;
}

.emblatech__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.emblatech__viewport.is-dragging {
  cursor: grabbing;
}

.emblatech__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.emblatech__slide {
  position: relative;
  width: 250px;
  padding-left: 10px;
}

.emblatech__slide__inner {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 190px;
}

@media (max-width: 768px) {
  .emblatech__slide {
    width: 150px;
    padding-left: 10px;
  }

  .emblatech__slide__inner {
    width: 70px;
    height: 100px;
  }
}

.emblatech__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 100%;
  /* min-height: 100%;
  min-width: 100%;
  max-width: none; */
  transform: translate(-50%, -50%);
}

.emblatech__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #636b6b;
  padding: 0;
}

@media (max-width: 768px) {
  .emblatech__button {
    width: 20px;
    height: 20px;
  }
}

.emblatech__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.emblatech__button__svg {
  width: 100%;
  height: 100%;
}

.emblatech__buttonprev {
  left: 27px;
}

.emblatech__buttonnext {
  right: 27px;
}

.techname {
  text-align: center;
  color: var(--chakra-colors-gray-600);
  font-weight: 800;
}

@media (max-width: 768px) {
  .techname {
    font-size: 12px;
    font-weight: 700;
  }
}
