.span {
  font-family: "Dancing Script", cursive;
  font-size: 40px;
}

.title {
  font-family: "Nunito", sans-serif;
}

.goUp {
  animation: 1.5s anim-goUp ease-out;
}
@keyframes anim-goUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* slider */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7.5));
  }
}
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 90%;
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}

@media (max-width: 768px) {
  .slider::before,
  .slider::after {
    background: none;
  }
}
.slidetrack {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 15);
}
.slide {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 25px;
  max-width: 250px;
}
